import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  reportOpen: false,
  prescriptionOpen: false,
}

export const patientSlice = createSlice({
  name: 'patientModal',
  initialState,
  reducers: {
    openReportModal: (state, action) => {
      state.reportOpen = true
    },
    closeReportModal: (state, action) => {
      state.reportOpen = false
    },
    openPrescriptionModal: (state, action) => {
      state.prescriptionOpen = true
    },
    closePrescriptionModal: (state, action) => {
      state.prescriptionOpen = false
    },
  },
})

export const { openReportModal, closeReportModal, openPrescriptionModal, closePrescriptionModal } =
  patientSlice.actions
// export const userInfo = state => state.userInfo;

export default patientSlice.reducer
