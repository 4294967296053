import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    changeSidebarShow: (state, action) => {
      const { sidebarShow } = action.payload
      state.sidebarShow = sidebarShow
    },
    changeAsideShow: (state, action) => {
      const { asideShow } = action.payload
      state.asideShow = asideShow
    },
    changeTheme: (state, action) => {
      const { theme } = action.payload
      state.theme = theme
    },
  },
})

export const { changeSidebarShow, changeAsideShow, changeTheme } = settingSlice.actions

export default settingSlice.reducer
