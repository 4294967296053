import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  loading: false,
  error: '',
  data: {
    id: '',
    userType: '',
    userName: '',
    userTel: '',
    userMobileTel: '',
    sex: '',
    hospitalId: '',
    code: '',
    patientUserId: '',
    patientUserName: '',
    patientUserAge: '',
    level: '',
    role: '',
  },
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    changeUserInfo: (state, action) => {
      const {
        id,
        userType,
        userName,
        userTel,
        userMobileTel,
        sex,
        hospitalId,
        code,
        patientUserId,
        patientUserName,
        patientUserAge,
        level,
        role,
      } = action.payload
      state.data = {
        id,
        userType,
        userName,
        userTel,
        userMobileTel,
        sex,
        hospitalId,
        code,
        patientUserId,
        patientUserName,
        patientUserAge,
        level,
        role,
      }
    },

    // insert: (state, action) => {
    //   const todo = {
    //     id: id++,
    //     text: action.payload,
    //     done: false,
    //   };
    //   state.todos.push(todo);
    // },
    // toggle: (state, { payload: id }) => {
    //   const index = state.todos.findIndex(todo => todo.id === id);
    //   state.todos[index].done = !state.todos[index].done;
    // },
    // remove: (state, { payload: id }) => {
    //   const index = state.todos.findIndex(todo => todo.id === id);
    //   state.todos.splice(index, 1);
    // },
  },
})

export const { changeUserInfo } = userInfoSlice.actions
// export const userInfo = state => state.userInfo;

export default userInfoSlice.reducer
