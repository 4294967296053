import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  open: false,
  severity: 'info', // error, warning, info, success
  message: '',
}

export const modalSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    openAlert: (state, action) => {
      state.open = true
      state.severity = action.payload.severity
      state.message = action.payload.message
    },
    closeAlert: (state, action) => {
      state.open = false
      state.severity = 'info'
      state.message = ''
    },
  },
})

export const { openAlert, closeAlert } = modalSlice.actions

export default modalSlice.reducer
