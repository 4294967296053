/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit'

/**
 * Sylvy's reducers
 * budgetReducer
 * loginReducer
 */

import userInfo from './modules/userInfo'
import setting from './modules/setting'
import alert from './modules/alert'
import modal from './modules/modal'
import patientModal from './modules/patientModal'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer() {
  return combineReducers({
    userInfo,
    setting,
    modal,
    alert,
    patientModal,
  })
}
