import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  open: false,
  link: '',
  data: {},
  detailOpen: false,
  detailLink: '',
  detailData: {},
  loadingOpen: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.open = true
      state.link = action.payload.link
      state.data = action.payload.data
    },
    closeModal: (state, action) => {
      state.open = false
      state.link = ''
      state.data = {}
    },
    openDetailModal: (state, action) => {
      state.detailOpen = true
      state.detailLink = action.payload.detailLink
      state.detailData = action.payload.detailData
    },
    closeDetailModal: (state, action) => {
      state.detailOpen = false
      state.detailLink = ''
      state.detailData = {}
    },
    openLoadingModal: (state, action) => {
      state.loadingOpen = true
    },
    closeLoadingModal: (state, action) => {
      state.loadingOpen = false
    },
  },
})

export const {
  openModal,
  closeModal,
  openDetailModal,
  closeDetailModal,
  openLoadingModal,
  closeLoadingModal,
} = modalSlice.actions
// export const userInfo = state => state.userInfo;

export default modalSlice.reducer
